import {PropsWithChildren, forwardRef} from 'react';
import {type InertiaLinkProps, Link} from '@inertiajs/react';
import {CaretRight, DotsThree} from '@phosphor-icons/react';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import clsx from 'clsx';
import ConditionalWrapper from '@/components/ConditionalWrapper';
import Tooltip from '@/components/Tooltip';

interface RootProps {
    defaultOpen?: boolean;
    modal?: boolean;
    open?: boolean;
    onOpenChange?: (open: boolean) => void;
}

interface ItemProps {
    className?: string;
    isDisabled?: boolean;
}

interface RadioItemProps {
    className?: string;
    isDisabled?: boolean;
    value: string;
}

interface ActionProps {
    disabledTooltip?: string;
    href?: string;
    isDisabled?: boolean;
    Icon: any;
    label: string;
    onClick?: () => void;
    onSuccess?: () => void;
    method?: InertiaLinkProps['method'];
    theme?: 'default' | 'destructive';
}

interface ContentProps {
    align?: 'start' | 'center' | 'end';
    className?: string;
}

export const ITEM_ACTION_CLASSES = (isDisabled: boolean, theme: 'default' | 'destructive') => {
    return clsx(
        'mx-1 my-0.5 flex w-[calc(100%-0.5rem)] flex-grow cursor-pointer items-start space-x-2 whitespace-normal break-words rounded-lg px-4 py-2 text-sm font-medium tracking-[-0.015rem] outline-none focus:outline-none focus:ring-2 focus:ring-offset-2',
        {
            'cursor-not-allowed opacity-40': isDisabled,
            'transition duration-500 ease-out hover:duration-100 focus:outline-none': !isDisabled,
            'text-teal-600': theme === 'default',
            'hover:bg-yellow-100/50 focus:bg-yellow-100/50 focus:ring-yellow-200':
                !isDisabled && theme === 'default',
            'text-red-600': theme === 'destructive',
            'bg-red-50 hover:bg-red-100 hover:text-red-800 focus:bg-red-100 focus:text-red-800 focus:ring-red-300':
                !isDisabled && theme === 'destructive'
        }
    );
};

export const ITEM_ACTION_ICON_CLASSES = '-ml-1 mr-1 h-5 w-5 flex-shrink-0';

const Root: React.FC<PropsWithChildren<RootProps>> = ({
    children,
    defaultOpen = false,
    modal = true,
    open = false,
    onOpenChange = () => {}
}) => (
    <DropdownMenuPrimitive.Root
        defaultOpen={defaultOpen}
        {...(open && !!onOpenChange && {open, onOpenChange})}
        modal={modal}
    >
        {children}
    </DropdownMenuPrimitive.Root>
);

const Trigger: React.FC<WithOptionalProperty<PropsWithChildren, 'children'>> = ({children}) => (
    <DropdownMenuPrimitive.Trigger
        className="_self-start group inline-flex animate-fadeIn cursor-pointer rounded-xl text-teal-800 focus:outline-none"
        data-testid="user-menu"
        asChild={!!children}
    >
        {children || (
            <span className="ease flex h-[28px] w-[34px] flex-shrink-0 items-center justify-center rounded-xl border border-gray-200 bg-white text-teal-800 transition duration-100 group-hover:border-gray-300 group-hover:bg-gray-100 group-focus:ring-2 group-focus:ring-gray-100 group-focus:ring-offset-2">
                <DotsThree className="mx-[2px] h-[24px] w-[24px]" weight="bold" />
            </span>
        )}
    </DropdownMenuPrimitive.Trigger>
);

const Content: React.FC<PropsWithChildren<ContentProps>> = ({
    align = 'end',
    children,
    className = ''
}) => (
    <DropdownMenuPrimitive.Portal>
        <DropdownMenuPrimitive.Content
            align={align}
            side="bottom"
            className={`dropshadow z-50 w-full min-w-[14rem] max-w-[24rem] origin-top-right cursor-pointer rounded-xl bg-white py-0.5 text-left shadow-xl ring-1 ring-black ring-opacity-5 will-change-[opacity,transform] data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade data-[side=right]:animate-slideLeftAndFade data-[side=top]:animate-slideDownAndFade focus:outline-none ${className}`}
            data-testid="user-menu-options"
        >
            {children}
            <DropdownMenuPrimitive.Arrow className="translate-x-[8px] fill-gray-200" />
        </DropdownMenuPrimitive.Content>
    </DropdownMenuPrimitive.Portal>
);

const Item: React.FC<PropsWithChildren<ItemProps>> = ({children, className = '', isDisabled}) => (
    <DropdownMenuPrimitive.Item asChild className={className} disabled={isDisabled}>
        {children}
    </DropdownMenuPrimitive.Item>
);

const ItemIndicator: React.FC<PropsWithChildren> = () => (
    <DropdownMenuPrimitive.ItemIndicator className="absolute left-0 inline-flex w-[25px] items-center justify-center">
        dot
    </DropdownMenuPrimitive.ItemIndicator>
);

const Action: React.FC<ActionProps> = forwardRef<HTMLButtonElement, ActionProps>(
    (
        {
            disabledTooltip,
            href,
            Icon,
            isDisabled = false,
            label,
            method = 'get',
            onClick,
            onSuccess,
            theme = 'default'
        },
        ref
    ) => (
        <ConditionalWrapper
            condition={isDisabled && disabledTooltip}
            wrapper={children => (
                <Tooltip.Provider>
                    <Tooltip.Item label={disabledTooltip!}>
                        <span>{children}</span>
                    </Tooltip.Item>
                </Tooltip.Provider>
            )}
        >
            <ConditionalWrapper
                condition={!!href && !isDisabled}
                fallbackWrapper={children => (
                    <button
                        onClick={() => {
                            if (!isDisabled && onClick) {
                                onClick();
                            }
                        }}
                        className={`w-full max-w-[calc(100%-0.5rem)] ${ITEM_ACTION_CLASSES(
                            isDisabled,
                            theme
                        )}`}
                        ref={ref}
                        type="button"
                    >
                        {children}
                    </button>
                )}
                wrapper={children => (
                    <Link
                        href={String(href)}
                        className={ITEM_ACTION_CLASSES(isDisabled, theme)}
                        method={method}
                        disabled={isDisabled}
                        {...(onClick &&
                            method === 'get' && {
                                onClick: () => {
                                    if (onClick) {
                                        onClick();
                                    }
                                }
                            })}
                        onSuccess={() => {
                            if (onSuccess) {
                                onSuccess();
                            }
                        }}
                    >
                        {children}
                    </Link>
                )}
            >
                <Icon className={ITEM_ACTION_ICON_CLASSES} />
                <span>{label}</span>
            </ConditionalWrapper>
        </ConditionalWrapper>
    )
);
Action.displayName = 'Action';

const Label: React.FC<PropsWithChildren> = ({children}) => (
    <DropdownMenuPrimitive.Label className="-mb-1 px-4 py-2 text-[13px] text-gray-500">
        {children}
    </DropdownMenuPrimitive.Label>
);

const Separator: React.FC = () => (
    <DropdownMenuPrimitive.Separator className="m-1 h-[1px] bg-gray-100" />
);

const SubContent: React.FC<PropsWithChildren> = ({children}) => (
    <DropdownMenuPrimitive.SubContent className="dropshadow z-50 w-full max-w-[24rem] origin-top-right cursor-pointer rounded-xl bg-white py-0.5 text-left shadow-xl ring-1 ring-black ring-opacity-5 will-change-[opacity,transform] data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade data-[side=right]:animate-slideLeftAndFade data-[side=top]:animate-slideDownAndFade focus:outline-none">
        {children}
    </DropdownMenuPrimitive.SubContent>
);

const SubTrigger: React.FC<PropsWithChildren> = ({children}) => (
    <DropdownMenuPrimitive.SubTrigger className={`${ITEM_ACTION_CLASSES(false, 'default')}`}>
        {children}
        <div className="ml-auto pl-4">
            <CaretRight className="h-4 w-4" />
        </div>
    </DropdownMenuPrimitive.SubTrigger>
);

const RadioItem: React.FC<PropsWithChildren<RadioItemProps>> = ({
    children,
    className = '',
    value
}) => (
    <DropdownMenuPrimitive.RadioItem
        className={`${ITEM_ACTION_CLASSES(
            false,
            'default'
        )} relative !h-auto !w-full max-w-[calc(100%-0.5rem)] !py-1 !pl-6 font-normal data-[state="checked"]:font-medium ${className}`}
        value={value}
    >
        <DropdownMenuPrimitive.ItemIndicator className="absolute left-0 top-[-1px] flex h-full w-4 items-center justify-center">
            <div className="h-[6px] w-[6px] flex-shrink-0 rounded-full bg-teal-600" />
        </DropdownMenuPrimitive.ItemIndicator>
        {children}
    </DropdownMenuPrimitive.RadioItem>
);

export default {
    Action,
    Arrow: DropdownMenuPrimitive.Arrow,
    Content,
    Item,
    ItemIndicator,
    Label,
    RadioGroup: DropdownMenuPrimitive.RadioGroup,
    RadioItem,
    Portal: DropdownMenuPrimitive.Portal,
    Root,
    Separator,
    Sub: DropdownMenuPrimitive.Sub,
    SubContent,
    SubTrigger,
    Trigger
};
