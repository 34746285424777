import {useEffect, useState} from 'react';
import {WarningCircle, X} from '@phosphor-icons/react';
import * as DialogPrimitive from '@radix-ui/react-alert-dialog';
import clsx from 'clsx';
import Button from '@/components/Button';
import HelpButton from '@/components/Button/Help';
import ConditionalWrapper from '@/components/ConditionalWrapper';
import CopyCurrentUrlButton from '@/components/CopyCurrentUrlButton';
import FormInput from '@/components/Form/Input';
import Spinner from '@/components/Spinner';
import useDialog from '@/hooks/useDialog';
import {HelpTopics} from '@/hooks/useHelp';
import useI18n from '@/hooks/useI18n';
import {FormFooter, SIZE_CLASSES} from './';

interface Props extends UIDialog {
    autoCloseOnAction?: boolean;
    canDismiss?: boolean;
    children?: React.ReactNode;
    confirmText?: string;
    containerClassName?: string;
    onDismiss?: () => void;
    footer?: (close: () => void) => React.ReactNode;
    handleSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
    helpTopic?: HelpTopics;
    Icon?: any;
    iconTheme?: keyof typeof THEMES;
    showCopyCurrentUrlButton?: boolean;
    size?: keyof typeof SIZE_CLASSES;
}

export const THEMES = {
    blue: 'bg-blue-100 text-blue-600',
    green: 'bg-green-100 text-green-600',
    red: 'bg-red-100 text-red-600',
    teal: 'bg-teal-100 text-teal-600',
    yellow: 'bg-yellow-100 text-yellow-600'
};

const AlertDialog: React.FC<Props> = ({
    autoCloseOnAction = true,
    cancel,
    canDismiss,
    children,
    confirm,
    confirmText,
    containerClassName = '',
    onDismiss,
    footer,
    handleSubmit,
    helpTopic,
    Icon,
    iconTheme = 'teal',
    isDestructive,
    isDisabled,
    isFetching,
    shouldClose,
    showCopyCurrentUrlButton,
    size = 'md',
    text,
    title,
    type
}) => {
    const {t} = useI18n();
    const [hasConfirmed, setHasConfirmed] = useState<boolean>(false);
    const [confirmTextValue, setConfirmTextValue] = useState<string>('');
    const dialog = useDialog(confirm, cancel, isFetching);

    const handleCancel = () => {
        if (autoCloseOnAction) {
            dialog.prepareClose('cancel');
        } else if (cancel?.onClick) {
            cancel.onClick();
        }
    };

    const handleConfirmTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmTextValue(event.target.value);

        if (event.target.value === confirmText) {
            setHasConfirmed(true);
        } else {
            setHasConfirmed(false);
        }
    };

    const handleConfirm = () => {
        if (autoCloseOnAction) {
            dialog.prepareClose('confirm');
        } else if (confirm?.onClick) {
            confirm.onClick();
        }
    };

    const handleDismiss = () => {
        if (isFetching) return;

        if (type === 'dialog') {
            dialog.prepareClose('cancel');
        } else if (type === 'modal' && onDismiss) {
            onDismiss();
        } else if (canDismiss) {
            dialog.prepareClose('cancel');
        }
    };

    useEffect(() => {
        if (shouldClose && !dialog.isClosing && autoCloseOnAction) {
            dialog.prepareClose('cancel');
        }
    }, [shouldClose]);

    const canConfirm = !confirmText || (confirmText && hasConfirmed);

    return (
        <DialogPrimitive.Portal>
            <DialogPrimitive.Overlay asChild>
                <div
                    className={`fixed bottom-0 left-0 right-0 top-0 z-[51] w-full bg-gray-700 bg-opacity-75 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0`}
                    onClick={handleDismiss}
                />
            </DialogPrimitive.Overlay>
            <DialogPrimitive.Content
                className={`fixed left-1/2 top-1/2 z-[52] mx-auto w-full -translate-x-1/2 -translate-y-1/2 outline-none drop-shadow-xl data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] ${SIZE_CLASSES[size]}`}
            >
                <div
                    className={`m-4 overflow-auto rounded-lg bg-white p-4 shadow-xl drop-shadow-xl sm:p-6 ${containerClassName}`}
                >
                    <ConditionalWrapper
                        condition={!!handleSubmit}
                        wrapper={children => (
                            <form
                                className="flex h-full flex-col self-start"
                                onSubmit={handleSubmit}
                            >
                                {children}
                            </form>
                        )}
                    >
                        <ConditionalWrapper
                            condition={isDestructive && type === 'dialog'}
                            wrapper={children => (
                                <div className="flex space-x-2">
                                    <div className="flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full bg-red-100 p-1 text-red-600">
                                        <WarningCircle className="h-6 w-6" />
                                    </div>
                                    {children}
                                </div>
                            )}
                        >
                            {type === 'modal' && onDismiss && (
                                <div className="absolute right-2 top-2 flex h-7 items-center">
                                    <DialogPrimitive.Cancel asChild onClick={onDismiss}>
                                        <button
                                            type="button"
                                            className="rounded-lg bg-gray-100 p-1 text-teal-800  outline-none ring-offset-2 transition duration-200 ease-out hover:bg-teal-200 focus:bg-opacity-100 focus:ring-2 focus:ring-teal-400"
                                        >
                                            <X className="h-5 w-5" />
                                        </button>
                                    </DialogPrimitive.Cancel>
                                </div>
                            )}
                            <div
                                className={clsx(
                                    'scrollbar-rounded-md max-h-[calc(100vh-10rem)] w-full overflow-auto scrollbar-thin scrollbar-track-teal-100 scrollbar-thumb-teal-600',
                                    {'text-center': type === 'modal'}
                                )}
                            >
                                {Icon && (
                                    <div
                                        className={`mx-auto mb-4 flex h-16 w-16 items-center justify-center rounded-full p-2 ${THEMES[iconTheme]}`}
                                    >
                                        <Icon className="h-[85%] w-[85%] text-current" />
                                    </div>
                                )}
                                {!children && isFetching && type === 'modal' && (
                                    <div className="mb-4">
                                        <Spinner size="h-8 text-teal-800 w-8" />
                                    </div>
                                )}
                                <DialogPrimitive.Title className="mb-2 text-lg font-medium leading-snug tracking-tight text-teal-950">
                                    {title}
                                </DialogPrimitive.Title>
                                {text && (
                                    <DialogPrimitive.Description
                                        className="mb-1 whitespace-pre-line text-[15px] text-gray-500"
                                        dangerouslySetInnerHTML={{__html: text}}
                                    />
                                )}
                                <div className="space-y-4">
                                    {text && children && <div className="mt-2 text-[15px]" />}
                                    {children}
                                    {confirmText && (
                                        <div className="mt-4 text-[15px]">
                                            <FormInput
                                                id="confirm"
                                                label={t('general.typeToConfirm', {
                                                    confirm: confirmText
                                                })}
                                                onChange={handleConfirmTextChange}
                                                value={confirmTextValue}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </ConditionalWrapper>

                        {(cancel || confirm || helpTopic || showCopyCurrentUrlButton) && (
                            <footer className="-mx-4 -mb-4 mt-6 flex items-center justify-between space-x-2 border-t border-gray-100 bg-gray-50 px-4 py-4 sm:-mx-6 sm:-mb-6 sm:px-6">
                                <div>
                                    {helpTopic && <HelpButton topic={helpTopic} />}
                                    {showCopyCurrentUrlButton && <CopyCurrentUrlButton />}
                                </div>
                                {(cancel || confirm) && (
                                    <div
                                        className={clsx(
                                            'flex flex-grow items-center justify-end space-x-2',
                                            {
                                                'flex-grow': type === 'modal'
                                            }
                                        )}
                                    >
                                        {footer ? (
                                            footer(() => dialog.prepareClose('cancel'))
                                        ) : (
                                            <>
                                                {cancel && (
                                                    <DialogPrimitive.Cancel
                                                        onClick={handleCancel}
                                                        asChild
                                                    >
                                                        <Button
                                                            className={
                                                                type === 'modal'
                                                                    ? 'w-full justify-center'
                                                                    : ''
                                                            }
                                                            disabled={isFetching || isDisabled}
                                                            isFetching={cancel?.processing}
                                                            theme="white"
                                                        >
                                                            {cancel.label || t('general.cancel')}
                                                        </Button>
                                                    </DialogPrimitive.Cancel>
                                                )}
                                                {confirm && (
                                                    <DialogPrimitive.Action
                                                        onClick={handleConfirm}
                                                        asChild
                                                    >
                                                        <Button
                                                            className={
                                                                type === 'modal'
                                                                    ? 'w-full justify-center'
                                                                    : ''
                                                            }
                                                            disabled={
                                                                isFetching ||
                                                                !canConfirm ||
                                                                isDisabled
                                                            }
                                                            isFetching={
                                                                isFetching || confirm?.processing
                                                            }
                                                            theme={
                                                                isDestructive
                                                                    ? 'destructive'
                                                                    : 'primary'
                                                            }
                                                            type="button"
                                                        >
                                                            {confirm.label || t('general.confirm')}
                                                        </Button>
                                                    </DialogPrimitive.Action>
                                                )}
                                            </>
                                        )}
                                    </div>
                                )}
                            </footer>
                        )}
                    </ConditionalWrapper>
                </div>
            </DialogPrimitive.Content>
        </DialogPrimitive.Portal>
    );
};

const AlertDialogWrapper = {
    Root: DialogPrimitive.Root,
    Trigger: DialogPrimitive.Trigger,
    Container: AlertDialog,
    FormFooter
};

export default AlertDialogWrapper;
